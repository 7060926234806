.preview-popover {
  position: absolute;
  z-index: 1000;
  width: 450px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  border: 1px solid #e5e5e5;
  font-size: .875em;
  text-wrap: pretty;

  &:has(.preview-popover-image) {
    width: 650px; // Increased to accommodate larger image
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  // Arrow styles for popover positioned below the link (arrow pointing up)
  &.below::before {
    content: "";
    position: absolute;
    top: -8px;
    left: var(--arrow-offset, 50%);
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #e5e5e5;
  }

  &.below::after {
    content: "";
    position: absolute;
    top: -7px;
    left: var(--arrow-offset, 50%);
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
  }

  // Arrow styles for popover positioned above the link (arrow pointing down)
  &.above::before {
    content: "";
    position: absolute;
    bottom: -8px;
    left: var(--arrow-offset, 50%);
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #e5e5e5;
  }

  &.above::after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: var(--arrow-offset, 50%);
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid white;
  }

  &-loading {
    padding: 16px;
    text-align: center;
    color: #666;
  }

  &-container {
    display: flex;
    border-radius: 8px;
    overflow: hidden; // Ensure image doesn't overflow corners
  }

  &-image {
    flex: 0 0 250px;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    // min-width: 0; // Prevent text from expanding container
  }

  &-header {
    padding: 12px 16px;
    // border-bottom: 1px solid #e5e5e5;
    flex-shrink: 0; // Prevent header from shrinking
    background: white; // Ensure header is opaque
  }

  &-type {
    font-size: .8em;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  &-title {
    font-size: 1.1em;
    margin: 0;
    font-weight: 600;
    color: #1a1a1a;
  }

  &-content {
    color: #4a4a4a;
    padding: 0px 16px;
    flex: 1 1 auto; // Allow content to grow and shrink
    overflow-y: auto; // Add scrolling if content is too long
    // overflow-y:hidden;
    // text-overflow: ellipsis;
    background: white; // Ensure content is opaque
  }

  &-footer {
    padding: 8px 16px;
    // border-top: 1px solid #e5e5e5;
    text-align: right;
    flex-shrink: 0; // Prevent footer from shrinking
    background: white; // Ensure footer is opaque
  }
}
