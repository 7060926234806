[data-target-results-container] {
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }

  /*
  Code Mentor often generates this type of list:

  1. **Question 1**:
    - Answer 1
    - Answer 2

  2. **Question 2**:
    - Answer 1
    - Answer 2

  It looks better with the margin-bottom removed from the paragraph tags.
  */
  li p {
    margin-bottom: 0;
  }

  /*
  Only the direct ul or ol elements within the container will have their
  non-first li elements styled with the margin. Nested lists within
  these ul or ol elements will not be affected.
  */
  > ul > li:not(:first-child),
  > ol > li:not(:first-child) {
      margin-top: $paragraph-margin-bottom;
  }
}

.mentor-btn-rainbow {
  padding: 1.5px;
  background-image: linear-gradient(45deg, #ff0080, #ff8c00, #40e0d0, #ff0080);
  // background-image: linear-gradient(45deg, #C93D36, #61AD64, #61ADAD, #C98C36, #6F61AD);
  // background-image: linear-gradient(45deg, #FF7E73, #ABE5B1, #ABE0E5, #FFC873, #B8ABE5);
}
