/*
Native SVG icons that replace the Font Awesome font-based icons.
*/

.icon {
  display: inline-flex;
  align-self: center;
}

.icon svg, .icon img {
  // 1x scale is 1em by 1em. Other scale factors are multiples.
  height: 1em;
  width: 1em;

  fill: currentColor;
}

.icon.baseline svg, .icon img {
  // top: .125em;
  top: calc(2em / 14);
  position: relative;
}


/*
Scale factors
1x scale is 1em by 1em. Other scale factors are multiples.
*/
$icon-scale-factors: (2, 3, 4, 5);

@each $factor in $icon-scale-factors {
  .icon.scale#{$factor}x svg, .icon.scale#{$factor}x img {
    width: #{$factor}em;
    height: #{$factor}em;
  }
}


// Tighten up the spacing on the left side of the icon.
// Useful for wide compound icons, like the skill level chevrons
// which are built up in HTML as three chevron icons next to each other.
.icon.tight-left svg, .icon.tight-left img {
  margin-left: -.3em;
}


/*
Animations

spin: 360deg rotation (also: animate-spin)
*/

.icon.spin svg, .icon.spin img, .animate-spin {
  animation: icon-spin 2s infinite linear
}

@keyframes icon-spin {
  0% {
      transform: rotate(0)
  }

  100% {
      transform: rotate(359deg)
  }
}


/*
List Icons
-------------------------
<ul class="icon-ul list-unstyled">
  <li>{% icon 'v4/check' class='icon-li' %}Foo</li>
  <li>{% icon 'v4/check' class='icon-li' %}Bar</li>
</ul>
*/

$icon-li-width: 1.5em; // how much to indent the icon "bullet"

.icon-ul {
  padding-left: 0;
  margin-left: $icon-li-width;
  list-style-type: none;
  > li {
    position: relative;
  }
}

.icon-li {
  position: absolute;
  left: -$icon-li-width;
  width: $icon-li-width;
  top: calc(2em / 14);
  text-align: center;
}


// --- Legacy icons (FontAwesome v4) ---

@import "fontawesome.scss";

// DEBUG: Uncomment the following lines to highlight any Font Awesome
// font-based icons so we can replace them with our SVG system

// .fa {
//   border: 4px dashed red;
//   background: yellow;
//   // color: white;
//   font-style: normal;
// }
// .fa:before {
//   content: "❌";
// }
