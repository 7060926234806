@use "sass:color";

// Fancy codeblock header colors for background and text
$code-color-defs: (
  // name, primary color
  ("codeblock--blue", #d8e3eb),
  ("codeblock--green", #d7e8d9),
  ("codeblock--grey", #dddddd),
  ("codeblock--purple", #e4d7e8),
  ("codeblock--red", #fad8d2),
  ("codeblock--yellow", #f3e2bb),
);

@each $name, $color in $code-color-defs {
  .#{$name} {
    background-color: #{$color};
    color: color.adjust($color, $lightness:-40%);
  }

  .#{$name}-action:hover {
    color: color.adjust($color, $lightness:-60%);
    filter: drop-shadow(1px 1px 1px color.adjust($color, $lightness:-20%));
  }
}

.icon.codeblock__header--icon-lower svg {
    top: .16em !important;
}

.codeblock__header--icon-disabled {
  filter: opacity(33%);
}

.highlight {
  // Clear floats to make the code block full width
  // even with floating image in the vicinity
  clear: both;
  margin-bottom: $paragraph-margin-bottom;
}

/* to make lines scroll instead of wrap */
/* from http://stackoverflow.com/a/23393920 */

.highlight pre {
  overflow-x: auto; // This sets the scrolling in x
  overflow-wrap: normal;
  position: relative;
}

.highlight pre code {
  white-space: pre; // Force <code> to respect <pre> formatting
  word-wrap: normal; // Fix overflow-x scrolling in Safari
}

.linenos {
  @extend .noselect;
  // Prevent user selection on Pygments line numbers
  user-select: none;
  color: rgba(27, 31, 35, 0.3);

  margin-right: 0.5em;
  border-right: 1px solid #dcdcdc;

  // Join vertical line gaps to make one solid divider line
  padding-top: 0.2em;
  padding-bottom: 0.2em;

  padding-right: 0.5em;
}

// This applies to "plain" <pre> elements with syntax highlighting AND
// "fancy" codeblocks with a header
.highlight pre,
pre {
  line-height: 1.5em;
  background-color: #f6f6f6;
  padding: 1em;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 80%;
  display: block;
  overflow-x: auto;
}

.highlight--with-header pre {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.highlight.highlight--with-header {
  // Fancy codeblocks (with header) get their bottom margin
  // from the surrounding .codeblock <div>
  margin-bottom: 0;
}

.codeblock {
  // Clear floats to make the code block full width
  // even with floating image in the vicinity
  clear: both;

  .codeblock__header {
    font-size: 1rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding-left: 1em;
    overflow-x: auto;
  }

  .codeblock__output-toggle {
    cursor: pointer;
    padding-left:1em;
    padding-right:1em;
  }

  .codeblock__copy {
    cursor: pointer;
    position: absolute;
    top:0;
    right:0;
    color:#888;
    padding:0;
  }

  .d-hover-only {
    opacity: 0; // Screenreaders will still display this
    transition: opacity 0.1s;
  }

  :hover {
    .d-hover-only {
      opacity: 1;
    }
  }
}

/* Customize scrollbars */
/* For Google Chrome */
.highlight pre::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.highlight pre::-webkit-scrollbar-thumb {
  background: #ddd;
}
.highlight pre::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
/*For Internet Explorer*/
body .highlight pre {
  scrollbar-face-color: #ddd;
  scrollbar-track-color: rgba(0, 0, 0, 0);
}

/* New Syntax Highlighting Scheme */
.highlight .hll {
  background-color: #fae28c; // matches sidebar TOC highlight

  border-radius: 3px;

  // Padding to make sure lines join together so the border radius doesn't look weird
  padding-top: 0.35em;
  padding-bottom: 0.35em;

  // Need to adjust margins for left/right padding so that the highlighted
  // lines align with non-highlighted lines
  padding-right: 0.35em;
  margin-right: -0.35em;

  padding-left: 0.35em;
  margin-left: -0.35em;
}
.highlight .c {
  color: #8f5902;
  font-style: normal;
}
.highlight .g {
  color: #000000;
}
.highlight .k {
  color: #204a87;
  font-weight: normal;
}
.highlight .l {
  color: #000000;
}
.highlight .n {
  color: #000000;
}
.highlight .o {
  color: #ce5c00;
  font-weight: normal;
}
.highlight .x {
  color: #000000;
}

.highlight.yaml .l {
  color: #204a87;
}
.highlight.yaml .no {
  color: #6c757d;
}

.highlight .p {
  color: #000000;
  font-weight: normal;
}
.highlight .cm {
  color: #8f5902;
  font-style: normal;
}
.highlight .cp {
  color: #4e9a06;
  font-style: normal;
} // C preprocessor
.highlight .c1 {
  color: #8f5902;
  font-style: normal;
}
.highlight .ch {
  color: #8f5902;
  font-style: normal;
} // #! shebang
.highlight .cs {
  color: #8f5902;
  font-style: normal;
}
.highlight .gd {
  color: #a40000;
}
.highlight .ge {
  color: #000000;
  font-style: normal;
}
.highlight .gr {
  color: #ef2929;
}
.highlight .gh {
  color: #000080;
  font-weight: normal;
}
.highlight .gi {
  color: #00a000;
}
.highlight .go {
  color: #6c757d;
  font-style: normal;
} // REPL output
.highlight .gp {
  color: #8f5902;
} // REPL prompt
.highlight .gs {
  color: #000000;
  font-weight: normal;
}
.highlight .gu {
  color: #800080;
  font-weight: normal;
}
.highlight .gt {
  color: #a40000;
  font-weight: normal;
}
.highlight .kc {
  color: #204a87;
  font-weight: normal;
}
.highlight .kd {
  color: #204a87;
  font-weight: normal;
}
.highlight .kn {
  color: #204a87;
  font-weight: normal;
}
.highlight .kp {
  color: #204a87;
  font-weight: normal;
}
.highlight .kr {
  color: #204a87;
  font-weight: normal;
}
.highlight .kt {
  color: #204a87;
  font-weight: normal;
}
.highlight .ld {
  color: #000000;
}
.highlight .m {
  color: #0000cf;
  font-weight: normal;
}
.highlight .s {
  color: #4e9a06;
}
.highlight .na {
  color: #c4a000;
}
.highlight .nb {
  color: #204a87;
}
.highlight .nc {
  color: #000000;
}
.highlight .no {
  color: #000000;
}
.highlight .nd {
  color: #5c35cc;
  font-weight: normal;
}
.highlight .ni {
  color: #ce5c00;
}
.highlight .ne {
  color: #cc0000;
  font-weight: normal;
}
.highlight .nf {
  color: #000000;
}
.highlight .nl {
  color: #f57900;
}
.highlight .nn {
  color: #204a87;
}
.highlight .nx {
  color: #000000;
}
.highlight .py {
  color: #000000;
}
.highlight .nt {
  color: #204a87;
  font-weight: normal;
}
.highlight .nv {
  color: #000000;
}
.highlight .ow {
  color: #204a87;
  font-weight: normal;
}
.highlight .w {
  color: transparent;
  text-decoration: underline;
}
.highlight .mf {
  color: #0000cf;
  font-weight: normal;
}
.highlight .mh {
  color: #0000cf;
  font-weight: normal;
}
.highlight .mi {
  color: #0000cf;
  font-weight: normal;
}
.highlight .mo {
  color: #0000cf;
  font-weight: normal;
}
.highlight .mb {
  color: #0000cf;
  font-weight: normal;
}
.highlight .sb {
  color: #4e9a06;
}
.highlight .sc {
  color: #4e9a06;
}
.highlight .sd {
  color: #8f5902;
  font-style: normal;
}
.highlight .s2 {
  color: #4e9a06;
}
.highlight .se {
  color: #4e9a06;
}
.highlight .sh {
  color: #4e9a06;
}
.highlight .si {
  color: #1b6700;
}
.highlight .sa {
  color: #1b6700;
}
.highlight .sx {
  color: #4e9a06;
}
.highlight .sr {
  color: #4e9a06;
}
.highlight .s1 {
  color: #4e9a06;
}
.highlight .ss {
  color: #4e9a06;
}
.highlight .bp {
  color: #3465a4;
}
.highlight .vc {
  color: #000000;
}
.highlight .vg {
  color: #000000;
}
.highlight .vi {
  color: #000000;
}
.highlight .il {
  color: #0000cf;
  font-weight: normal;
}

// pygments-pytest defines these
.highlight .-Color-Red {
  color: #cc0000;
  font-weight: normal;
}
.highlight .-Color-Bold-Red {
  color: #cc0000;
  font-weight: bold;
}
.highlight .-Color-Green {
  color: #00a000;
  font-weight: normal;
}
.highlight .-Color-Bold-Green {
  color: #00a000;
  font-weight: bold;
}
.highlight .-Color-Yellow {
  color: #c4a000;
  font-weight: normal;
}
.highlight .-Color-Bold-Yellow {
  color: #c4a000;
  font-weight: bold;
}
