/*
Notifications
*/

.notification-container {
  position: fixed;
  top: 5em;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9999999; // high z-index to display on top of lesson video player
  backface-visibility: hidden;
  // make sure content below can be interacted with (e.g. hovering over the
  // lesson player "fullscreen" button)
  pointer-events: none;
}

.notification-container .notification {
  opacity: 1;
}

.notification-container .notification .notification-progress {
  background-color: #fff;
  opacity: 0.33;
  height: .2em;
  width: 100%;
  border-radius: 4px;

  &.animating {
    width: 0%;
  }
}
