/*
Color utilities for social media sharing targets
*/
@use "sass:color";

$share-colors: (
  "twitter": #55acee,
  "x-twitter": #000,
  "facebook": #3b5998,
  "linkedin": #0077b5,
  "reddit": #ff5700,
  "hacker-news": #ff6600,
  "bluesky": #1185fe,
  "email": $brand,
  "print": $brand
);

@each $service-name, $color in $share-colors {
  .share-#{$service-name} {
    background-color: $color;
    color: #fff !important;
    &:hover {
      background-color: color.adjust($color, $lightness:-10%);
      text-decoration: underline;
    }
    &:focus {
      background-color: color.adjust($color, $lightness:-10%);
      text-decoration: underline;
      box-shadow: 0 0 0 $input-btn-focus-width rgba($color, .25);
    }
  }
}
