// Image zoom / lightbox

.js-lightbox::before {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url("data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzOCIgaGVpZ2h0PSIzOCI+PHBhdGggZD0iTTExLjY1IDI3aC0uMDdhLjYzLjYzIDAgMCAxLS4zOC0uMTcuNjUuNjUgMCAwIDEtLjItLjQ3di00LjlhLjY1LjY1IDAgMCAxIC42NS0uNjUuNjUuNjUgMCAwIDEgLjY1LjY1djMuMzRsNC42MS00LjYyYS42NS42NSAwIDAgMSAuOTIgMCAuNjUuNjUgMCAwIDEgMCAuOTJMMTMuMiAyNS43aDMuMzRhLjY1LjY1IDAgMCAxIC42NS42NS42NS42NSAwIDAgMS0uNjUuNjV6bTguNTMtOS4xOGEuNjQuNjQgMCAwIDEgMC0uOTFsNC42MS00LjYyaC0zLjM0YS42NS42NSAwIDAgMS0uNjQtLjY0LjY1LjY1IDAgMCAxIC42NC0uNjVoNC45YS42NC42NCAwIDAgMSAuNDYuMTkuNjUuNjUgMCAwIDEgLjA5LjF2LjAxYS42NS42NSAwIDAgMSAuMS4zNHY0LjlhLjY1LjY1IDAgMCAxLS42NS42NS42NS42NSAwIDAgMS0uNjUtLjY1VjEzLjJsLTQuNjEgNC42MmEuNjQuNjQgMCAwIDEtLjQ2LjE5LjY0LjY0IDAgMCAxLS40NS0uMTl6IiBmaWxsPSIjZmZmIi8+PC9zdmc+");
  content: "";
  width: 38px;
  height: 38px;
  position: absolute !important;
  right: 15px;
  margin-top: 0;
  border-radius: 3px;
}

.js-lightbox:hover::before {
  background-color: $brand;
}

// Fix "jump to top" on mobile
// https://github.com/biati-digital/glightbox/issues/22#issuecomment-418204785
.glightbox-open {
  height: auto;
}

// Add default white background for transparent images
.gslide-image img {
  background-color: white;
}

.gslide-description {
  background: transparent !important;
  color: white;
  text-align: center;
}
