/*
Flexbox gap utilities, like in Bootstrap 5

https://getbootstrap.com/docs/5.3/utilities/spacing/#gap

https://stackoverflow.com/questions/64204395/how-to-make-space-between-items-using-d-flex
*/


@each $size, $length in $spacers {
  .gap-#{$size} {
    gap: $length !important;
  }
  .row-gap-#{$size} {
    row-gap: $length !important;
  }
  .col-gap-#{$size} {
    column-gap: $length !important;
  }
}
