@use "sass:color";

@import "icons.scss";

$font-family-sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

// $blue: #158CBA;
// $blue: rgb(50, 104, 150);
// $blue: #306998;
// $python: rgb(69, 112, 153);
// $blue: color.adjust($python, $lightness:20%);
$blue: color.adjust(#306998, $lightness:20%);
// $blue: color.adjust(#18354c, $lightness:10%);
$red: #ff0000;

// $cta: rgb(241, 99, 52);
$cta: #e53e1e;
// $cta: #fb3e15;
$secondary: #f0f0f0;
// $dark: #183647;
$brand: #18354c;
$dark: #18354c;
// $light: #f0f0f0;
$light: #f6f6f6;
$input: #647077; // == $input-border-color

$lightblue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$body-color: #222;

// Add custom theme colors
$theme-colors: (
  "input": $input,
  "lightblue": $lightblue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "teal": $teal,
  "cyan": $cyan,
  "body": $body-color,
);

$navbar-dark-hover-color: rgba($dark, 0.75);
$navbar-dark-active-color: $dark;
$navbar-dark-disabled-color: rgba($dark, 0.25);

$mark-bg: #ffffcc;

$paragraph-margin-bottom: 1.125rem;
$spacer: $paragraph-margin-bottom;
$alert-margin-bottom: $paragraph-margin-bottom;
$breadcrumb-margin-bottom: $paragraph-margin-bottom;

$breadcrumb-padding-x: 0;
$breadcrumb-bg: none;

$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

// $font-size-base: 1.2375rem;
// $font-size-base: 1.125rem;
$font-size-base: 1.125rem;
// $font-size-base: 1.25rem;

$h1-font-size: $font-size-base * 2.1875;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.53125;
$h4-font-size: $font-size-base * 1.3125;
$h5-font-size: $font-size-base * 1.09375;
$h6-font-size: $font-size-base;

$kbd-padding-y: 0.2rem;
$kbd-padding-x: 0.4rem;
$kbd-color: $body-color;
$kbd-bg: #fcfcfc;

// $headings-font-weight: bold;
$headings-line-height: 1.2;

$text-muted: #999;

$breadcrumb-divider: quote("›");

$navbar-top-margin: 5rem;

// $link-decoration: underline;

// This variable affects the `.h-*` and `.w-*` classes.
// Add w-33 and w-66
$sizes: (
  25: 25%,
  33: 33%,
  50: 50%,
  66: 66%,
  75: 75%,
  100: 100%,
);

// Slightly reduce max container size on XL screens
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1080px,
);

// Import default bootstrap here so variable settings are applied
// and we can start overriding stuff.
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Bootstrap overrides
h1 {
  font-weight: bold;
  line-height: 1.1em;
}

h2 {
  margin-top: $paragraph-margin-bottom * 2;
  font-weight: bold;
}

h3 {
  margin-top: $paragraph-margin-bottom * 2;
  // font-weight: bold;
}

h4 {
  margin-top: $paragraph-margin-bottom * 2;
}

// Copied from _reboot.scss
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

// li p {
//   margin-bottom: 0;
// }

figure {
  margin: 0 0 $paragraph-margin-bottom;
}

.page-body {
  font-size: 110%;
}

.article img {
  margin-bottom: $paragraph-margin-bottom;
}

.article-body ul li,
.article-body ol li {
  margin-bottom: calc($paragraph-margin-bottom / 4);
}

.article-body ol ol,
.article-body ul ul,
.article-body ol ul,
.article-body ul ol {
  margin-top: calc($paragraph-margin-bottom / 4);
  margin-bottom: calc($paragraph-margin-bottom / 4);
}

// GPT-4 likes to create Markdown lists that look like this:
//
// 1. **Heading**:
//     - Item 1
//     - Item 2
//
// 2. **Heading**:
//     - Item 1
//     - Item 2
//
.article-body li ul {
  margin-bottom: $paragraph-margin-bottom;
}

.article-body .toc ul li,
.article-body .toc ol li {
  margin-top: 0;
  margin-bottom: 0;
}

.article-body .toc ol ol,
.article-body .toc ul ul,
.article-body .toc ol ul,
.article-body .toc ul ol {
  margin-top: 0;
  margin-bottom: 0;
}

// Hide sidebar TOC h3 elements
.sidebar-sticky .toc li ul {
  display: none;
}

.sidebar-sticky .toc {
  font-size: 90%;
  // max-height: 300px;
  // overflow: scroll;
}

// Sidebar TOC scrollspy highlighting for active section
table-of-contents {
  max-height: 80vh;
  overflow-y: auto;

  li {
    padding-left: 2px;
    color: #619ccd;
  }

  li.active {
    border-radius: 4px;
    padding-left: 2px;
    list-style-type: "→   ";
    background: #fae28c;
  }

  li.active a {
    background: #fae28c;
  }
}

.navbar-dark {
  background-color: $dark;
}

.container.main-content,
.container-fluid.main-content {
  margin-top: 2rem;
}

// Add an extra margin to account for fixed top nav overlap
// to make sure content isn't hidden behind the nav.
nav.fixed-top + .container.main-content {
  margin-top: $navbar-top-margin;
}

// Push main content further down if we're displaying a
// promo message in the nav
nav.nav-message + .container.main-content {
  margin-top: $navbar-top-margin + 2rem;
}

// Prevent # hash anchor links from getting overlapped by the main nav
// html { scroll-padding-top: XXX; } seems to be the CSS answer, unfortunately
// it's buggy on Safari (but works on Chrome and Firefox):
//
// https://css-tricks.com/fixed-headers-on-page-links-and-overlapping-content-oh-my/
html {
  scroll-padding-top: $navbar-top-margin;
  scroll-behavior: smooth;
}

.sidebar-nav-message {
  top: $navbar-top-margin + 2rem !important;
}

blockquote {
  color: #6f6f6f;
  padding: 10.5px 21px;
  margin: 0 0 $paragraph-margin-bottom;
  border-left: 0.2em solid #dddddd;

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}

// Inline monospace / code blocks
code {
  font-size: 82.5%;
  color: $body-color;

  // Wrap long <code> lines to keep them from overflowing,
  // while preserving whitespace (avoid whitespace collapse)
  //
  // https://3.basecamp.com/4210154/buckets/12741492/messages/5318434781
  //
  // pre-wrap:
  // Sequences of white space are preserved. Lines are broken at newline
  // characters, at <br>, and as necessary to fill line boxes.
  white-space: pre-wrap;
}

blockquote code {
  color: #6f6f6f;
}

// Better visibility for inline code blocks in quizzes
.quizviewer code {
  padding: 1px 4px;
  border-radius: 3px !important;
  background-color: #f6f6f6;
}

// Avoid <code> background clashing with <select> on
// fill-in-the-blanks cards
.quizviewer code:has(select) {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.quizviewer pre code {
  padding: 0;
}

// Make quiz code editor match up with Markdown code blocks
.CodeMirror pre {
  font-family: $font-family-monospace !important;
  font-size: 80% !important;
}

// textarea.text-monospace {
//   font-size: 80%;
// }

// input.text-monospace {
//   font-size: 80%;
// }

// Only display on hover (fade in)
.visible-hover {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.highlight-hover-dark {
  background-color: inherit;

  &:hover {
    background-color: color.adjust($dark, $lightness:10%);
  }
}

.highlight-hover-light {
  background-color: inherit;

  &:hover {
    background-color: $light;
  }
}

// Focus state for cards without a visible link that can receive focus::after
.card-body:has(a.stretched-link:focus) {
  border-radius: $border-radius;
  box-shadow: 0 0 0 $btn-focus-width rgba(0, 123, 255, 0.25);
}

.quiz-embed-iframe {
  border: 0;
}

// Prevent `code` blocks from getting wrapped inside tables
td code {
  white-space: nowrap;
}

// Search Box
.search-field {
  background-color: rgba(0, 0, 0, 0);
  border-color: #647077;
}

.search-field:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.search-field-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

// Unstyled links
.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
  color: inherit;
  // text-decoration: inherit;
}

// Permanent links on headlines
.with-headerlinks {
  h1:hover > a.headerlink,
  h2:hover > a.headerlink,
  h3:hover > a.headerlink,
  h4:hover > a.headerlink,
  h5:hover > a.headerlink,
  h6:hover > a.headerlink {
    visibility: visible;
  }
}

.headerlink:hover {
  // Underlining the non-breaking space + headerlink indicator (#)
  // looks odd, so let's style these as non-underlined
  text-decoration: none;
}

a.headerlink {
  font-size: 0.8em;
  visibility: hidden;
}

a.headerlink::before {
  // Set contents via CSS so that Google search doesn't pick up the
  // # headerlink indicator in the HTML.
  //
  // We pre-pend a non-breaking space character (\00a0) to keep the
  // browser from word-wrapping the permalink indicator onto a blank line.
  content: "\00a0#";
}

// Super Clicky CTA Buttons
// https://thrivethemes.com/button-design/
.btn-primary {
  border-style: solid;
  border-width: 0 1px 4px 1px;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  // border-radius: 3px !important;
  background-color: $cta;
  border-bottom-width: 4px;
  border-color: $cta;
  border-bottom-color: color.adjust($cta, $lightness:-5%);
  color: #fff;
}

.btn-primary:not(.disabled):hover {
  background-color: color.adjust($cta, $lightness:5%);
  border-color: color.adjust($cta, $lightness:5%);
  border-bottom-color: color.adjust($cta, $lightness:-2.5%);
}

// Depressed state: decrease bottom border width from 4px to 2px
// and then add extra spacing on top to make it appear as if the
// button "sinks" into the page:
.btn-primary:not(.disabled):active {
  outline: 0 !important;
  border-top-width: 2px;
  border-bottom-width: 2px;
  background-color: color.adjust($cta, $lightness:5%) !important;
  border-color: color.adjust($cta, $lightness:5%) !important;
  border-bottom-color: color.adjust($cta, $lightness:-5%) !important;
}

.btn-primary:not(.disabled):focus {
  outline: 0 !important;
}

.btn-primary:focus {
  outline: 0 !important;
  color: #fff;
  background-color: color.adjust($cta, $lightness:5%);
  border-color: color.adjust($cta, $lightness:5%);
  border-bottom-color: color.adjust($cta, $lightness:-2.5%);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: color.adjust($cta, $lightness:5%) !important;
  border-color: color.adjust($cta, $lightness:5%) !important;
  border-bottom-color: color.adjust($cta, $lightness:-5%) !important;
}

/*
  Bootstrap for _buttons.scss button mixins examples:

  @mixin button-variant(
    $background,
    $border,
    $hover-background: color.adjust($background, $lightness:-7.5%),
    $hover-border: color.adjust($border, $lightness:-10%),
    $active-background: color.adjust($background, $lightness:-10%),
    $active-border: color.adjust($border, $lightness:-12.5%)
  )
  @include button-variant(
    $background,
    $border,
    optional $hover-background,
    optional $hover-border,
    optional $active-background,
    optional $active-border
  );
  @include button-variant($light, $dark);
  */

  /*
  @mixin button-outline-variant(
    $color,
    $color-hover: color-yiq($color),
    $active-background: $color,
    $active-border: $color
  )
  @mixin button-outline-variant(
    $color,
    optional $color-hover, // contents when hovered
    optional $active-background, // background when hovered
    optional $active-border // border when hovered
  );
*/

// For Basecamp-style "..." ellipsis dropdown menus

.btn-context-menu-circle {
  // @include button-outline-variant(
  //   $text-muted, // $body-color vs $text-muted
  //   $color-hover: $body-color,
  //   $active-background: $light,
  //   $active-border: transparent,
  // );
  @include button-outline-variant(
    $body-color,
    $color-hover: $body-color,
    $active-background: $light,
    $active-border: transparent,
  );
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  border-color: transparent;
}

.btn-context-menu {
  @include button-outline-variant(
    $body-color,
    $color-hover: $body-color,
    $active-background: $light,
    $active-border: transparent,
  );
  border-color: transparent;
}

//
// Text formatting
//
.text-wrap {
  white-space: normal;
}

.text-wrap-balance {
  text-wrap: balance;
}

h1,
h2,
h3,
h4,
.text-wrap-pretty {
  text-wrap: pretty;
}

// Visited links
.article a:visited:not(.btn, .alert-link) {
  // color: #0d5875;
  color: color.adjust($link-color, $lightness:-15%);
}

// .article a:not(.btn) {
//   text-decoration: underline;
// }

// Navbar spacing
.navbar {
  padding: 0 0.75rem;
}

.navbar-brand {
  padding: 0.75rem 0;

  :hover {
    filter: opacity(80%);
  }
}

.navbar-dark .navbar-nav .nav-link {
  padding: 1rem 1rem;
  margin-right: 0.5rem;
  -webkit-tap-highlight-color: rgba($dark, 0.5);
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .dropdown-item {
  color: #eee;
}

.navbar-dark .navbar-nav .show > .nav-link {
  color: #eee;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:active {
  /*background-color: rgb(74, 86, 93);*/
  // background-color: #647077;
  background-color: color.adjust($dark, $lightness:10%);
  color: #eee;
}

.navbar-dark .navbar-nav .nav-link:active {
  /*background-color: rgb(74, 86, 93);*/
  /*background-color: #717D84;*/
  // background-color: #717D84;
  background-color: color.adjust($dark, $lightness:10%);
  color: #eee;
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: #eee;
}

/* Navbar dropdown styles*/
.navbar-dark .navbar-nav .dropdown-menu {
  padding: 0;
  border-top: 0;
  margin-top: 0;
  // Bootstrap .dropdown-menu has background-color: #fff; that leads
  // to a shimmer around the dropdown corners. Avoid by setting a dark
  // background color here as well.
  background-color: $dark;

  // Ensure top and bottom element have rounded corners
  > :first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  > :last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}



.navbar-dark .navbar-nav .dropdown-item {
  background-color: $dark;
  padding: 0.75rem;
  &:hover {
    background-color: color.adjust($dark, $lightness:10%);
    padding: 0.75rem;
    color: #eee;
  }
}

// Navbar dropdown caret -- bump it down slightly to make it center-of-mass
// aligned with the text
.dropdown-toggle::after {
  margin-left: .19em;
  vertical-align: .19em;
}


/* Dark dropdown styles */
.dropdown-item-dark {
  background-color: $dark;
  color: #eee;
  &:hover, &:focus {
    background-color: color.adjust($dark, $lightness:10%);
    color: #eee;
  }
}

// Sidebar
/* Sidebar modules for boxing content */
.sidebar-module {
  padding: 1rem;
}

.sidebar-module-inset {
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: $paragraph-margin-bottom;
  /*border-radius: 0;*/
  /*border: 0 !important;*/
  /*background-color: #fafafa;*/
}

.sidebar-module-inset p:last-child,
.sidebar-module-inset ul:last-child,
.sidebar-module-inset ol:last-child {
  margin-bottom: 0;
}

.sidebar-sticky {
  position: sticky;
  top: $navbar-top-margin;
}

// Series Navigation
.series-nav {
  background-color: $light;
  border-radius: 0.25rem;

  .nav-item {
    margin-bottom: 0;
  }
}

// Sticky footer
// Remove when this lands https://github.com/twbs/bootstrap/pull/26674
body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.main-content {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

footer {
  margin-top: auto;
}

// https://getbootstrap.com/docs/4.0/examples/sticky-footer-navbar/
.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  bottom: 0;
  width: 100%;
  height: auto;
  line-height: 30px;
}

.footer p {
  margin-bottom: 0;
  padding: 0.5em;
}

.footer .container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.footer a {
  color: #999;
  text-decoration: underline;
}

.guarantee {
  background: #fff9c4;
  padding-left: $spacer * 3;
  padding-right: $spacer * 3;
  padding-top: $spacer * 3;

  .h2 {
    color: $red;
  }
}

// Comments
.comment-body {
  // Ensure long URLs also get split up
  word-break: break-word;
}

.comment-body > *:last-child {
  margin-top: 0;
  margin-bottom: 0;
}

// Alerts
// Remove extra bottom margin on content.
.alert > *:last-child {
  margin-top: 0;
  margin-bottom: 0;
}

// Collapse blocks -- remove extra margin on content
// While the block expands, collapse block classes change
// from .collapse -> .collapsing -> .collapse.show
.collapse > .card-body > *:last-child {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.collapsing > .card-body > *:last-child {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

// Fake marker highlights
.marker-highlight {
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(255, 224, 0, 0.3),
    rgba(255, 224, 0, 0.7) 95%,
    rgba(255, 224, 0, 0.1)
  );
}

// Search autocomplete dropdown
mark.autocomplete-highlight {
  padding: 0;
  color: inherit;
  background-color: inherit;

  text-decoration: underline;
}

// Video Player
.lesson-player-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
}

// Video lesson tabview
.tab-header-nav-link {
  // Make padding consistent with buttons above the tab headers
  // (mark as completed, bookmark ...). Make sure the start of
  // the tab header text aligns with the button text above.
  padding: 0.375rem 0.75rem;
}

// Responsive tables
// Remove the top border on headless tables
table:not(:has(thead)) tr:first-child td {
  border-top: none !important;
}

// Helpers
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

// Hide VueJS loading state
[v-cloak] > * {
  display: none;
}

[v-cloak]::before {
  content: "Loading…";
}

// htmx -- easy loading indicators
// https://htmx.org/attributes/hx-indicator/
.htmx-indicator {
  display: none;
}

.htmx-request .htmx-indicator {
  // CSS animations won't trigger for "inline", so
  // this must be an inline-block
  display: inline-block;
}

.htmx-request.htmx-indicator {
  display: inline-block;
}

.htmx-indicator-fullscreen-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: wait;
}

//
// Custom styles for the join and join_team page to improve how the
// plan choice cards look on smaller screens (maximize vertical space)
//
$rp-join-page-cards-breakpoint: 992px;

.rp-join-page-wrapper {
  @media all and (min-width: $rp-join-page-cards-breakpoint) {
    // Give it a bit more horizontal space
    width: 110%;
    margin-left: -5%;
  }
}

.rp-join-page-card {
  width: 320px;
  max-width: 90%;
}

.rp-join-page-card-spacer {
  margin: 0;

  // Magic value for the join page cards breakpoint where
  // it goes from one card to two cards per row. This is totall
  // hacky of course, but looks better than what we had before...
  @media all and (min-width: $rp-join-page-cards-breakpoint) {
    // This basically means same distance as "mb-4" but laid out vertically
    // so we can align the sales page cards correctly with the
    // individual/team switch
    margin-left: calc(($spacer * 1.5) / 2) !important;
    margin-right: calc(($spacer * 1.5) / 2) !important;
  }
}

//
// Input placeholder text
//
[placeholder] {
  text-overflow: ellipsis;
}

//
// Input group addons -- make them blend in with the input field more
//
.btn-input-addon {
  background-color: $light;
  color: $input-color;
  border-color: $input-border-color;
}

//
// Learning Path Cards
//

// Vertical connector line between learning paths.
// Height/distance is controlled with a pb-N class on the element.
.learning-path-card--connector {
  border-left: 1.5px solid $border-color;
  margin-left: 15%;
}

// #1, #2, #3 ... index indicators that overlay the
// top left corner of the card
.learning-path-card--index-flap {
  z-index: 1;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 2em;
  height: 1.5em;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  // background-color: $border-color !important; // blend in with border
}

// Vertical divider line with text, e.g.
// --------- or ---------
/*
Example:

<div class="vline-wrapper my-2">
  <div class="vline"><span class="vline-content px-2">or</span></div>
</div>
*/
.vline-wrapper {
  position: relative;
}

.vline {
  text-align: center;
}

.vline::after {
  background-color: $border-color;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  width: 100%;
}

.vline-content {
  color: $text-muted;
  background-color: white;
  position: relative;
  display: inline-block;
  z-index: 1;
}

// Prices

.text-diagonal-strikethrough-red {
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    red 49.5%,
    red 50.5%,
    transparent 52.25%
  );
}

.text-color-red {
  color: red !important; // "true red" #ff0000
}

// Keyboard Shortcuts <kbd>
kbd {
  padding: $kbd-padding-y $kbd-padding-x;
  font-size: $kbd-font-size;
  color: $kbd-color;
  background-color: $kbd-bg;

  border: 0.1rem solid #c9c9c9;
  border-bottom-color: #bcbcbc;
  box-shadow: 0 0.1rem 0 #b0b0b0;

  word-break: break-word;

  kbd {
    padding: 0;
    font-size: 100%;
    font-weight: $nested-kbd-font-weight;
    @include box-shadow(none);
  }
}

.keys kbd:after,
.md-typeset .keys kbd:before {
  position: relative;
  margin: 0;
  color: #bdbdbd;
  font-family: sans-serif;
  -moz-osx-font-smoothing: initial;
  -webkit-font-smoothing: initial;
  font-weight: 400;
}

.keys span {
  padding: 0 0.2rem;
  color: #bdbdbd;
}

.keys .key-backspace:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "←";
}

.keys .key-command:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "⌘";
}

.keys .key-windows:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "⊞";
}

.keys .key-caps-lock:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "⇪";
}

.keys .key-control:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "⌃";
}

.keys .key-meta:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "◆";
}

.keys .key-shift:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "⇧";
}

.keys .key-option:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "⌥";
}

.keys .key-tab:after {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "↹";
}

.keys .key-num-enter:after {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "↵";
}

.keys .key-enter:after {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "↩";
}

.keys .key-arrow-up:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "↑";
}

.keys .key-arrow-down:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "↓";
}

.keys .key-arrow-left:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "←";
}

.keys .key-arrow-right:before {
  padding: 0.2rem;
  vertical-align: baseline;
  content: "→";
}

// Avoid re-layout when the library/search infinite scroll loads in
.scrollbar-gutter-always {
  overflow-y: scroll;
  scrollbar-gutter: stable both-edges;
}

@import "code-highlighting.scss";
@import "notifications-badge.scss";
@import "lightbox.scss";
@import "preflight.scss";
@import "staff-menu.scss";
@import "notify.scss";
@import "gap.scss";
@import "share.scss";
@import "lesson-player.scss";
@import "code-mentor.scss";
@import "reference.scss";
@import "previews.scss";
