.d-hover-only {
  opacity: 0; // Screenreaders will still display this
  transition: opacity 0.1s;

  &:hover {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 2s ease-in-out;
}

.player-controls {
  z-index: 2; // display *below* end screen overlay
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;

  padding-right: 15%;
  padding-bottom: 15%;
}

.player-controls--exit {
  z-index: 3; // display *above* end screen overlay
}

.player-control--no-corners {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

// Vimeo lookalike
// .player-control-vimeo {
//   background-color: rgba(0, 0, 0, 0.9);
//   color: #fff;
//   &:hover {
//     background-color: rgb(101, 130, 153);
//     color: #000;
//   }
// }
