.ref-sidebar {
  overflow-y: auto;
  max-height: 100vh;
  // position: sticky;
}

.ref-sidebar-last-visible-item {
  border-bottom-width: 0 !important;
  border-bottom-right-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
}

.ref-link {
  position: relative;
  color: #222 !important; // body
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #619ccd; //link color
  text-underline-offset: .1em;

  &:hover {
    color:  #3676ab !important; // link hover
    text-decoration: underline;
    text-decoration-color: #3676ab; // link hover
  }

  &-popover {
    position: absolute;
    z-index: 1000;
    max-width: 300px;
    padding: 0;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    margin-top: 3px;

    &-title {
      padding: 8px 12px;
      margin: 0;
      font-weight: 600;
      border-bottom: 1px solid #e0e0e0;
      font-size: 15px;
    }

    &-description {
      padding: 12px;
      font-size: 14px;
      line-height: 1.5;
      color: #333;
    }

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      width: 10px;
      height: 10px;
      background: white;
      border-left: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      // Position is controlled by JS via --arrow-offset
      left: var(--arrow-offset, 50%);
      transform: translateX(-50%) rotate(45deg);
    }

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
}

// TODO look into visited state colors?
