// Notifications badge

.notifications-indicator-container {
  position: absolute;
  top: 35px;
  left: 35px;
}

.notifications-indicator {
  border-radius: 20px;
  background: #dc3545;
  position: absolute;
  top: 8px;
  left: 8px;
  transform: scale(0);
  transition: all .3s;
}

.notifications-indicator-visible {
  visibility: visible;
  animation: 0.3s ease forwards 1s animateIn;
  height: 14px !important;
  width: 14px !important;
  box-shadow: 0px 0px 0px 3px $dark !important;

  @keyframes animateIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(0.7);
    }
  }
}

.notifications-indicator-hidden {
  opacity: 0;
  transform: scale(0);
}
