// Position above all content in the lower left of the viewport
.staff-menu-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}

// Rounded borders on the corners not touching the sides of the viewport
.staff-menu-btn {
  border-radius: 0 3px 0 0;
}

.staff-menu-action-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  cursor: wait;
}

.staff-menu-action-overlay-contents {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  text-align: center;
}
